<template>
  <div class="login">
    <div class="content">
      <div class="title"></div>
      <div class="input-container">
        <div class="input-box" v-if="loginPage">
          <div class="input-box-top">
            <p class="loginP p1">{{$t("common.login")}}</p>
            <el-form
              :model="loginUser"
              :rules="rules"
              ref="loginForm"
              class="loginForm"
            >
              <el-form-item prop="userName">
                <span class="el-icon-user-solid icon"></span>
                <el-input
                  v-model="loginUser.userName"
                  :placeholder="$t('common.userName')"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <span class="el-icon-lock icon"></span>
                <el-input
                  v-model="loginUser.password"
                  :placeholder="$t('common.password')"
                  type="password"
                  @keyup.native.enter="submitForm('loginForm')"
                ></el-input>
              </el-form-item>
              <el-form-item class="pawleft">
                <label>
                  <el-checkbox
                    v-model="remember"
                    :label="$t('login.remember')"
                    name="type"
                  ></el-checkbox>
                </label>
                <label class="forgetPwd" @click="forgetPwd()">
                  {{$t('login.Forgot')}}?
                </label>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="submitForm('loginForm')"
                  class="submit_btn"
                  >{{$t("common.login")}}</el-button
                >
              </el-form-item>
            </el-form>
            <p class="bott bott0">
              {{$t("login.Noaccount")}}?
              <span class="bottSpan" @click="changePage('loginForm')"
                >{{$t("login.firstRegistration")}}</span
              >
            </p>
          </div>
        </div>
        <div class="input-box" v-else>
          <div class="input-box-top">
            <p class="loginP p2">邮 箱 注 册</p>
            <el-form
              :model="registerUser"
              :rules="rulesregister"
              ref="registerForm"
              class="loginForm re"
            >
              <el-form-item prop="email">
                <span class="el-icon-message icon"></span>
                <el-input
                  v-model="registerUser.email"
                  placeholder="请输入邮箱"
                  class="verifi"
                ></el-input>
              </el-form-item>
              <input
                type="password"
                name="password"
                style="position: absolute;left: -10000px;"
              />
              <el-form-item prop="verificationCode">
                <span class="el-icon-key icon"></span>
                <span
                  class="icon2 icon3"
                  v-if="countdown == 0"
                  @click="sendCode()"
                  >发送验证码</span
                >
                <span class="icon2 icon3 icon4" v-else>{{ countdown }} s</span>
                <el-input
                  v-model="registerUser.verificationCode"
                  placeholder="验证码"
                ></el-input>
              </el-form-item>
              <el-form-item prop="pwd">
                <span class="el-icon-lock icon"></span>
                <el-input
                  type="password"
                  v-model="registerUser.pwd"
                  placeholder="密码"
                ></el-input>
              </el-form-item>
              <el-form-item prop="surePassword">
                <span class="el-icon-lock icon"></span>
                <el-input
                  type="password"
                  v-model="registerUser.surePassword"
                  placeholder="确认密码"
                ></el-input>
              </el-form-item>
              <el-form-item class="form">
                <el-button
                  v-if="rememberRegister"
                  type="primary"
                  @click="register('registerForm')"
                  class="submit_btn"
                  >注 册</el-button
                >
                <el-button v-else type="info" class="disable" disabled
                  >注 册</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <p class="bott bott3">
            已有帐号?
            <span class="bottSpan" @click="changePage('registerForm')"
              >立即登录</span
            >
          </p>
        </div>
      </div>
      <div class="foot-container">
        <p>
          <a>鹦鹉塔科技&nbsp;|&nbsp;</a
          ><a href="https://beian.miit.gov.cn">沪ICP备2021021283号-1</a>
        </p>
      </div>
    </div>
    <div class="languageText">
      <el-dropdown trigger="click">
            <div class="el-dropdown-link dropdown-link">文/A：{{ languageText }}</div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="language('zh', '中文')"
                >中文</el-dropdown-item
              >
              <el-dropdown-item @click.native="language('en', 'English')"
                >English</el-dropdown-item
              >
            </el-dropdown-menu>
        </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import i18n from '../language'
export default {
  name: 'login',
  data () {
    return {
      languageText: "中文",
      loginPage: true,
      loginUser: {
        userName: '',
        password: ''
      },
      countdown: 0,
      partoken: '',
      rules: {
        userName: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ]
      },
      remember: true,
      rememberRegister: true,
      registerUser: {
        email: '',
        verificationCode: '',
        pwd: '',
        surePassword: ''
      },
      rulesregister: {
        email: [{ required: true, message: '邮箱不能为空', trigger: 'blur' }],
        verificationCode: [
          { required: true, message: '验证码不能为空', trigger: 'change' }
        ],
        pwd: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
        surePassword: [
          { required: true, message: '请确认密码', trigger: 'blur' }
        ]
      },
      isDownLoad: false,
      isIndex: false,
      isCommunity: false
    }
  },
  created () {
    this.partoken = this.$route.query.token
    if (this.partoken) {
      localStorage.setItem('eleToken', 'Bearer ' + this.partoken)
      localStorage.setItem('ChengtToken', this.partoken)
      this.$axios.get('/api/User/LoginByToken').then((res) => {
        const userAllInfoDto = res
        localStorage.setItem('USER_ID', userAllInfoDto.userId)
        localStorage.setItem('eleToken', 'Bearer ' + this.partoken)
        localStorage.setItem('ChengtToken', this.partoken)
        localStorage.setItem('nickName', userAllInfoDto.nickName)
        localStorage.setItem(
          'pictureUrlService',
          userAllInfoDto.pictureUrlService
        )
        localStorage.setItem('USER_INFO', JSON.stringify(userAllInfoDto))
        this.initUserInfo(userAllInfoDto)
        this.$router.push('/space')
      })
    }
    let language = sessionStorage.getItem('theLanguage')
      ? sessionStorage.getItem('theLanguage')
      : 'zh';
    this.language(language);
  },
  mounted () {
    if (this.partoken) {
    } else {
      this.getLoginUser()
    }
    let data = this.$route.query.type
    console.log(data)
    if (data == 'register') {
      this.loginPage = false
    }
    if (data == 'download') {
      this.isDownLoad = true
    }
    if (data == 'index') {
      this.isIndex = true
    }
    if (data == 'community') {
      this.isCommunity = true
    }
  },
  methods: {
    language(text, languageText = "") {
      let language = sessionStorage.getItem("theLanguage");
      sessionStorage.setItem("theLanguage", text);
      if (text == "zh") {
        this.languageText = "中文";
      } else {
        this.languageText = "English";
      }
	  if (text == language) {
        return 0;
      }
      if (languageText) {
        i18n.locale = text
      }
    },
    refresh() {
      location.reload();
    },
    // 获取记住的密码
    getLoginUser () {
      if (
        localStorage.getItem('userName') != 'null' &&
        localStorage.getItem('passWord') != 'null'
      ) {
        this.loginUser.userName = localStorage.getItem('userName')
        this.loginUser.password = localStorage.getItem('passWord')
      } else {
        this.loginUser = {
          userName: '',
          passWord: ''
        }
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .get(
              '/api/auth/auth?userName=' +
                this.loginUser.userName +
                '&password=' +
                this.loginUser.password
            )
            .then((res) => {
              console.log(res)
              // 登录成功，储存token
              // const {token} = res
              this.initPersonalSpaceId(res.userAllInfoDto.personSeatEnterprise)
              this.initCompanySpaceId(
                res.userAllInfoDto.seatEnterprise ||
                  res.userAllInfoDto.enterpriseSpaceId
              )
              const { token, userAllInfoDto } = res
              localStorage.setItem('USER_ID', userAllInfoDto.userId)
              localStorage.setItem('eleToken', 'Bearer ' + token)
              localStorage.setItem('ChengtToken', token)
              // localStorage.setItem("online", userinfo.ID);
              localStorage.setItem('nickName', userAllInfoDto.nickName)
              localStorage.setItem(
                'pictureUrlService',
                userAllInfoDto.pictureUrlService
              )
              localStorage.setItem('USER_INFO', JSON.stringify(userAllInfoDto))
              // 记住密码
              if (this.remember) {
                localStorage.setItem('nickName', res.userAllInfoDto.nickName)
                localStorage.setItem('userName', this.loginUser.userName)
                localStorage.setItem('passWord', this.loginUser.password)
              } else {
                // console.log('不记住密码')
                localStorage.removeItem('userName')
                localStorage.removeItem('passWord')
              }
              // 将用户信息存储到store中
              this.initUserInfo(userAllInfoDto)
              let menuList = res.userAllInfoDto.permissions
              let parmenuList = menuList.filter((item2) => {
                return item2.parentID == 0
              })
              // // 将每个父级的子级存进去
              var menus = []
              for (let item3 of parmenuList) {
                let obj = {}
                obj.name = item3.permissionName
                obj.index = item3.permissionDescribe
                obj.icon = item3.icon
                obj.includeCom = item3.permissionDescribe
                let arr = menuList.filter((item4) => {
                  return item4.parentID == item3.id
                })
                if (arr.length != 0) {
                  obj.hasChilder = true
                  obj.children = []
                  for (let child of arr) {
                    let objc = {}
                    objc.name = child.permissionName
                    objc.index = child.permissionDescribe
                    objc.icon = child.icon
                    objc.includeCom = child.permissionDescribe
                    objc.hasChilder = false
                    obj.children.push(objc)
                  }
                } else {
                  obj.hasChilder = false
                }
                menus.push(obj)
              }
              // console.log(menus)
              // localStorage.setItem("fixMenus",menus)
              this.initMenu(menus)

              if (this.isDownLoad) {
                this.$router.push('/download')
              } else if (this.isIndex) {
                this.$router.push('/index')
              } else if (this.isCommunity) {
                this.$router.push('/community')
              } else {
                if (res.userAllInfoDto.statu <= 0) {
                  this.$router.push('/adminSpace')
                } else {
                  this.$router.push('/space')
                }
              }
            })
        } else {
          // console.log("error submit!!");
          return false
        }
      })
    },
    changePage (formName) {
      this.loginPage = !this.loginPage
      this.$refs[formName].resetFields()
    },
    sendCode () {
      const reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/
      const value = this.registerUser.email
      if (value == '' || value == undefined || value == null) {
        this.$message({ type: 'warning', message: '请输入邮箱地址' })
        return
      } else {
        if (!reg.test(value)) {
          this.$message({ type: 'error', message: '请输入正确的邮箱地址' })
          return
        }
      }
      this.countdown = 60
      var formDate = new FormData()
      formDate.append('recipientArry[]', this.registerUser.email)
      formDate.append('mailTitle', '注册')
      var _this = this
      var time = window.setInterval(function () {
        if (_this.countdown === 0) {
          window.clearInterval(time)
        } else {
          _this.countdown -= 1
        }
      }, 1000)
      this.$axios.post('/api/Auth/PostEmails', formDate).then((res) => {
        this.$message({
          type: 'success',
          message: '验证码发送成功'
        })
      })
    },
    register (formName) {
      var userName = this.registerUser.email
      var passWord = this.registerUser.pwd
      var code = this.registerUser.verificationCode
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.registerUser.pwd != this.registerUser.surePassword) {
            this.$message({
              type: 'warning',
              message: '密码和确认密码不一致'
            })
            return
          }
          this.$axios
            .get(
              '/api/User/RegisterUser?userName=' +
                userName +
                '&password=' +
                passWord +
                '&code=' +
                code
            )
            .then((res) => {
              this.$confirm('注册成功, 前往登录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'success'
              }).then(() => {
                this.changePage(formName)
              })
            })
        }
      })
    },
    forgetPwd () {
      this.$router.push('/forgetPwd')
    },
    ...mapMutations('user', {
      initUserInfo: 'initUserInfo'
    }),
    ...mapMutations('menu', {
      initMenu: 'initMenu'
    }),
    ...mapMutations('spaceInfo', {
      initPersonalSpaceId: 'initPersonalSpaceId'
    }),
    ...mapMutations('spaceInfo', {
      initCompanySpaceId: 'initCompanySpaceId'
    })
  }
}
</script>

<style lang="less">
.login {
  width: 100%;
  // height: 100%;
  height: 969px;
  display: flex;
  align-items: center; /*定义父元素的元素垂直居中*/
  justify-content: center; /*定义子元素的里的元素水平居中*/
  background: url(../assets/Login/bg.png);
  background-size: 100% 100%;
  position: relative;
  .languageText{
    position: absolute;
    right: 40px;
    top: 20px;
    .dropdown-link{
      width: 120px;
      height: 40px;
      background: #2f374a;
      color: #fff;
      text-align: center;
      line-height: 40px;
      border-radius:6px;
    }
  }
  .el-button:focus,
  .el-button:hover {
    color: #fff;
  }
  .el-button {
    border: none;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    box-sizing: border-box;
    // height: 100vh;
    height: 969px;
    // min-height: 960px;
    width: 870px;
    padding: 90px 0 100px;

    // .title {
    // 	width: 580px;
    // 	height: 60px;
    // 	background: url(../assets/Login/title.png) no-repeat;
    // 	background-size: cover;
    // }

    .input-container {
      width: 100%;
      height: 514px;
      background: url(../assets/Login/loginbg.png) no-repeat center top;
      background-size: cover;

      .input-box {
        box-sizing: border-box;
        width: 48%;
        height: 100%;
        float: right;
        .input-box-top {
          padding: 10px 80px 0 50px;
        }
        .loginForm {
          .el-form-item__content {
            position: relative;
            .icon {
              position: absolute;
              font-size: 23px;
              z-index: 999;
              top: 48%;
              transform: translateY(-50%);
              left: 5px;
            }
            .icon2 {
              cursor: pointer;
              position: absolute;
              font-size: 23px;
              z-index: 999;
              top: 53%;
              transform: translateY(-50%);
              right: 3px;
              height: 34px;
            }
            .icon3 {
              font-size: 16px;
              padding: 0 10px 0 10px;
              background: #9bbed3;
              border-radius: 5px;
            }
            .icon4 {
              background: #c9c8c8;
              color: #9e9d9d;
              width: 50px;
            }
          }
          .pawleft {
            text-align: left;
            .forgetPwd {
              float: right;
              color: #f54752;
              cursor: pointer;
            }
          }
        }
        .re {
          .el-form-item {
            margin-bottom: 18px;
          }
        }
        .loginP {
          font-size: 18px;
          font-family: Microsoft YaHei;
          color: #32c5ff;
          line-height: 36px;
          font-weight: bold;
          margin: 120px auto 20px;
        }
        .p1 {
          text-align: left;
          padding-left: 112px;
        }
        .p2 {
          text-align: left;
          padding-left: 87px;
        }
        .bott {
          font-size: 15px;
          color: #2f374a;
          .bottSpan {
            color: #32c5ff;
            cursor: pointer;
          }
        }
        .bott0 {
          margin-top: 45px;
        }
        .bott2 {
          font-size: 12px;
        }
        .bott3 {
          margin-top: 10px;
        }
        input::placeholder {
          color: #000000;
        }
        .verifi {
          font-size: 10px;
          .el-input__inner {
            padding: 0 60px 0 35px;
          }
        }
        .el-input__inner {
          border: none;
          border-radius: 0;
          // border-bottom: 1px solid #000000;
          padding: 0 0 0 35px;
          background-color: #f4f4f8;
          border-radius: 5px;
        }
        input::-webkit-input-placeholder {
          color: #acacac;
        }
        input::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #acacac;
        }
        input:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #acacac;
        }
        input:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #acacac;
        }
        .el-checkbox__inner {
          border-radius: 0;
        }

        .el-button--primary {
          width: 100%;
          background: #2f374a;
          border-radius: 22px;
          font-size: 18px;
          margin: 20px auto 0px auto;
        }
        .form {
          .el-button--primary {
            margin: 0px auto 0px auto;
          }
        }
        .el-button--info {
          width: 100%;
          border-radius: 22px;
          font-size: 18px;
          margin: 20px auto 0px auto;
        }
      }
    }
    .foot-container {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2f374a;
      a {
        text-decoration: none;
      }
      a:link {
        color: #2f374a;
      } /* 未被访问的链接 */
      a:visited {
        color: #2f374a;
      } /* 已被访问的链接 */
      a:hover {
        color: #2f374a;
      } /* 鼠标指针移动到链接上 */
      a:active {
        color: #2f374a;
      } /* 正在被点击的链接 */
    }
  }
}
</style>
